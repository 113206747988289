import React, {useState} from 'react';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {GoogleLogin} from "react-google-login";
import Icon from "@material-ui/core/Icon";
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
//import { signin, signup,googlelogin} from '../../actions/auth';
import { googlelogin} from '../../actions/auth';
import dotenv from 'dotenv';

dotenv.config();

const Client_ID = '571576760999-rodco321qd7jlfgserql5d43992gpimr.apps.googleusercontent.com';
//const initialState = {firstName: '', lastName: '', email: '', password: '', confirmPassword: ''};

const Auth = () => {

    //const [showPassword, setShowPassword] = useState(false);

    //const [isSignup, setIsSignup] = useState(false);
    const isSignup = false;
    //const [formData, setFormData] = useState(initialState);

/*    const handleSubmit = (e) => {
        e.preventDefault();

        if(isSignup) {
            dispatch(signup(formData, history));
        } else {
            dispatch(signin(formData, history));
        }
    };*/

/*
    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const switchMode = () => {
        setIsSignup((prevIsSignup) => !prevIsSignup);
        setShowPassword(false);
    };

    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
*/

    const dispatch = useDispatch();

    const history = useHistory();

    const [errorLoginMsg, setErrorLoginMsg] = useState(false);

    const googleSuccess = async (res) => {
        //const result = res?.profileObj;
        const token = res?.tokenId;

        try {
            //dispatch({ type : AUTH, data: { result, token }});
            const ans = await dispatch(googlelogin(token, history));

            if(ans === null ) {
                setErrorLoginMsg(false);
                history.push('/');
            }
            else setErrorLoginMsg(true);

        } catch (error) {
            console.log(error);
        }
    };
    const googleFailure = (error) => {
        console.log(error);
        console.log("google sign in was unsuccessful. try again later");
        setErrorLoginMsg(false);
    };

    return (
        <Container component='main' maxwidth='xs'>
            <Paper elevation={3}>
                {errorLoginMsg && <Typography color={"secondary"}  variant='h5'>Error Login, try again</Typography>}
                <Avatar>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography variant='h5'>{isSignup ? 'Sign Up' : 'Sign In'}</Typography>
                <form>
                {/*<form onSubmit={handleSubmit}>*/}
{/*                    <Grid container spacing={2}>

                        {
                            isSignup && (
                                <>
                                    <Input name={'firstName'} label='First Name' handleChange={handleChange} autoFocus Focus half/>
                                    <Input name={'lastName'} label='Last Name' handleChange={handleChange} half/>
                                </>
                            )
                        }
                        <Input name={"email"} label='Email Address' handleChange={handleChange} type='email'/>
                        <Input name={"password"} label='Password' handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword}/>
                        {isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password"/>}
                    </Grid>
                    <Button type="submit" fullWidth variant={"contained"} color={"primary"}>
                        {isSignup ? 'Sign up' : 'Sign in'}
                    </Button>*/}
                    <GoogleLogin
                        clientId={Client_ID}
                        render={(renderProps) => (
                            <Button
                                color="primary"
                                fullWidth
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                                startIcon={<Icon />}
                                variant={"contained"}>
                                    Google Sign In
                            </Button>
                            )}
                        onSuccess={googleSuccess}
                        onFailure={googleFailure}
                        cookiePolicy={"single_host_origin"}

                            />
{/*
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            <Button onClick={switchMode}>
                                {isSignup ? 'Already have an account ? Sign In' : "Dont have an account ? Sign Up"}
                            </Button>
                        </Grid>
                    </Grid>*/}
                </form>
            </Paper>
        </Container>
    )
};

export default Auth;