import React, {useState} from 'react';
import Project from "./Project/Project";
import {Grid, CircularProgress, InputLabel, Select, MenuItem, FormControl} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {ALL, SUBJECTS_TYPES} from "../../../constants/subjectTypes";
import './Projects.css';

const Projects = ( { setCurrentProjectId }) => {

    const projects = useSelector((state) => state.projects);

    const values = SUBJECTS_TYPES;


    const [subject, setSubject] = useState('');

    const projectsFilter = Array.isArray(projects) ? projects.filter((project) =>  {
        if(subject === ALL.name || subject === '') {
            return true;
        }
        else {
            return project.subject === subject;
        }
    }) : [];

    function handleSelectorChange(e) {
        setSubject(e.target.value);
    }

return (
        <>
            <FormControl fullWidth size={"medium"} className="formControl">
                <InputLabel style={{paddingLeft: "15px"}}>Search Subject</InputLabel>
                <Select name='search-subject' variant='outlined' label='Search-Subject'
                        onChange={(e) => { handleSelectorChange(e)} }
                        inputProps={{
                            name: 'search-subject', variant: 'outlined', label: 'Search-Subject'
                        }}
                        value={subject}
                >
                    options={values.map((value, index) => {
                        return <MenuItem key={value.name} value={value.name}>{value.label}</MenuItem>;
                    })}
                </Select>
            </FormControl>

            {!projectsFilter.length ? <CircularProgress /> : (
                    <Grid component="span" container alignItems="stretch" spacing={3}>
                        {
                            projectsFilter.map((project) => (
                                <Grid container item key={project._id} style={{paddingTop: '10px'}} >
                                    <Project project={project} setCurrentProjectId={setCurrentProjectId}/>
                                </Grid>
                            ))
                        }
                    </Grid>
            )}
        </>
    );
};

export default Projects;