import React, {useEffect, useState} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import './Project.css';
import { Button, Typography, Paper, Grid } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useDispatch } from 'react-redux';
import { deleteProject, updateProject } from '../../../../actions/projects';
import {deleteImagesBucket} from '../../../../actions/imagesBucket';
import {LOCAL_STORAGE} from "../../../../constants/Constants";


const Project = ({ project, setCurrentProjectId }) => {

    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE));

    const [projectData, setProjectData] = useState({
        subject: project?.subject,
        eng: { title: project.eng?.title, description: project.eng?.description, client: project.eng?.client, architectures: project.eng?.architectures,
        simulation: project.eng?.simulation, partners: project.eng?.partners, interior_designer: project.eng?.interior_designer, photography: project.eng?.photography,
            project_manager: project.eng?.project_manager},
        heb: { title: project.heb?.title, description: project.heb?.description, client: project.heb?.client, architectures: project.heb?.architectures,
            simulation: project.heb?.simulation, partners: project.heb?.partners, interior_designer: project.heb?.interior_designer, photography: project.heb?.photography,
            project_manager: project.heb?.project_manager},
        images: project.images
    });

    useEffect(() => {
        if(projectData) setProjectData(projectData);
    }, [projectData]);

    async function handleRemoveImage(e, imageIndex, imageId) {
        e.preventDefault();
        (project.images).splice(imageIndex, 1);
        setProjectData({...projectData, images: project.images});
        await dispatch(updateProject(project._id, project));
        await dispatch(deleteImagesBucket(imageId));
    }

    return (
        <Paper className={"paper"}>
            <div >
                <Button style={{color:'black'}} size="small" onClick={() => setCurrentProjectId(project._id)} >
                    {user && <MoreHorizIcon /> }
                </Button>

                <Button style={{color:'black'}} size="small" onClick={() => dispatch(deleteProject(project._id))} >
                    {user && <DeleteIcon /> }
                </Button>
            </div>

            <div className="info">

                <Typography component="span" className={"title"} color={"primary"}>
                    Subject:
                    <Typography component="p" className={"result"} color={"secondary"}>
                        {project.subject}
                    </Typography>
                </Typography>
                {project.eng &&

                < Grid item className="english" >
                    <Typography component="span" variant={"h5"} className={"title"} color={"primary"} size={"medium"}>English</Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Title: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.title}</Typography> </Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Description: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.description}</Typography> </Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Client: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.client}</Typography> </Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Architectures: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.architectures}</Typography> </Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Simulation: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.simulation}</Typography> </Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Partners: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.partners}</Typography> </Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Interior Designer: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.interior_designer}</Typography> </Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Photography: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.photography}</Typography> </Typography>
                    <Typography  component="span" className={"title"} color={"primary"}>Project Manager: <Typography component="p" className={"result"} color={"secondary"}>{project.eng.project_manager}</Typography> </Typography>
                    </Grid>
                }
                {project.heb &&
                <Grid item className="hebrew">
                    <Typography component="span" variant={"h5"} className={"title"} color={"primary"}
                                size={"medium"}>עברית</Typography>
                    <Typography component="span" className={"title"} color={"primary"}>כותרת: <Typography component="p"
                                                                                                          className={"result"}
                                                                                                          color={"secondary"}>{project.heb.title}</Typography>
                    </Typography>
                    <Typography component="span" className={"title"} color={"primary"}>תאור: <Typography component="p"
                                                                                                         className={"result"}
                                                                                                         color={"secondary"}>{project.heb.description}</Typography>
                    </Typography>
                    <Typography component="span" className={"title"} color={"primary"}>לקוח: <Typography component="p"
                                                                                                         className={"result"}
                                                                                                         color={"secondary"}>{project.heb.client}</Typography>
                    </Typography>
                    <Typography component="span" className={"title"} color={"primary"}>אדריכלים: <Typography
                        component="p" className={"result"} color={"secondary"}>{project.heb.architectures}</Typography>
                    </Typography>
                    <Typography component="span" className={"title"} color={"primary"}>הדמייה: <Typography component="p"
                                                                                                           className={"result"}
                                                                                                           color={"secondary"}>{project.heb.simulation}</Typography>
                    </Typography>
                    <Typography component="span" className={"title"} color={"primary"}>שותפים: <Typography component="p"
                                                                                                           className={"result"}
                                                                                                           color={"secondary"}>{project.heb.partners}</Typography>
                    </Typography>
                    <Typography component="span" className={"title"} color={"primary"}>מעצב פנים: <Typography
                        component="p" className={"result"}
                        color={"secondary"}>{project.heb.interior_designer}</Typography> </Typography>
                    <Typography component="span" className={"title"} color={"primary"}>צילום: <Typography component="p"
                                                                                                          className={"result"}
                                                                                                          color={"secondary"}>{project.heb.photography}</Typography>
                    </Typography>
                    <Typography component="span" className={"title"} color={"primary"}>מנהל פרויקט: <Typography
                        component="p" className={"result"}
                        color={"secondary"}>{project.heb.project_manager}</Typography> </Typography>
                </Grid>
                }

                {project.images ?
                    <div>
                        <Typography  component="span" className={"title"} color={"primary"}>Number Of Images: <Typography component="p" className={"result"} color={"secondary"}>{project.images.length}</Typography> </Typography>
                        {(project.images).map((img, index) => (
                            <div key={index} className={"images"}>
                                <Button  style={{color:'black'}} size="small" className={"deleteButton"}
                                         onClick={(e) => {handleRemoveImage(e,index,img);} }
                                >
                                    {user && <DeleteIcon  />}
                                </Button>
                                {/*<img  src={img} alt={"img"} url={img}/>*/}
                                <img  src={"https://yossi-rokach-server.herokuapp.com/imagesBucket/" + img} alt={img} key={img}/>
                            </div>
                        ))}
                    </div>
                    : <div></div>}
            </div>
        </Paper>
    );
};

export default Project;