import React, { useState, useEffect} from 'react';
import { TextField, Button, Typography, Paper, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
//import FileBase from 'react-file-base64';
import {useDispatch} from 'react-redux';
import {createProject, updateProject} from "../../../actions/projects";
import { useSelector } from 'react-redux';
import {ALL, SUBJECTS_TYPES} from '../../../constants/subjectTypes';
import './ProjectForm.css';
import {postImageBucket} from "../../../actions/imagesBucket";
import {LOCAL_STORAGE} from "../../../constants/Constants";


const ProjectForm = ({ currentProjectId, setCurrentProjectId }) => {

    const dispatch = useDispatch();
    const [file,setFile] = useState([]);

    const fileSelected = (event) => {
        const tempArr = [];
        [...event.target.files].forEach(f => {
            tempArr.push(f);
        });
        setFile(tempArr);
    };


    const project = useSelector((state) => currentProjectId ? state.projects.find((p) => p._id === currentProjectId) : null);

    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE));

    useEffect(() => {
        if(project) setProjectData(project);
    }, [project]);

    async function handleSubmit(e) {
        e.preventDefault();
        const fd = await dispatch(postImageBucket(file));
        const files = await (fd.toString().split(","));

        if(projectData.images.length === 0) {
            projectData.images = files;
        } else {
            const arr = projectData.images.concat(files);
            projectData.images = arr;
        }

        if(currentProjectId) {
            dispatch(updateProject(currentProjectId, projectData));
        } else {
            dispatch(createProject(projectData));
        }
        clear();
    }

    const clear = () => {
        setCurrentProjectId(null);
        setProjectData({
            subject: selected,
            eng: {title: '', description: '', client: '', architectures: '', simulation: '', partners: '', interior_designer: '', photography: '', project_manager: ''},
            heb: {title: '', description: '', client: '', architectures: '', simulation: '', partners: '', interior_designer: '', photography: '', project_manager: ''},
            images: []
        });
    };

    const values = SUBJECTS_TYPES.filter((subject) => subject !== ALL);

    const [selected, setSelected] = useState('');

    function handleChange(event) {
        setSelected(event.target.value);
    }

    const [projectData, setProjectData] = useState({
        subject: selected,
        eng: {title: '', description: '', client: '', architectures: '', simulation: '', partners: '', interior_designer: '', photography: '', project_manager: ''},
        heb: {title: '', description: '', client: '', architectures: '', simulation: '', partners: '', interior_designer: '', photography: '', project_manager: ''},
        images: []
    });

    if(!user?.result?.name) {
        return (
            <Paper>
                <Typography variant='h6' align='center'>
                    please sign in to add
                </Typography>
            </Paper>
        )
    }

    return (
        <div className="sticky">
                    <Paper className="container">
                        <form autoComplete="off" noValidate onSubmit={(e) => handleSubmit(e)}>

                            <Typography variant='h6'>{currentProjectId ? 'Editing' : 'Creating'} Project</Typography>

                            <FormControl fullWidth size={"small"}>
                                <InputLabel style={{paddingLeft: "15px"}}>Subject</InputLabel>
                                <Select name='subject' variant='outlined' label='Subject' value={projectData.subject}
                                        onChange={(e) => {
                                            setProjectData({...projectData, subject: e.target.value});
                                            handleChange(e);
                                        }}
                                        inputProps={{
                                            name: 'subject', variant: 'outlined', label: 'Subject'
                                        }}
                                >
                                    {values.map((value, index) => {
                                        return <MenuItem key={value.name} value={value.name}>{value.label}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>

                            <TextField name='title' variant='outlined' label='Title - English' size={"small"} fullWidth value={projectData.eng.title}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, title: e.target.value}})}/>
                            <TextField name='description' variant='outlined' label='Description - English' size={"small"} fullWidth
                                       value={projectData.eng.description}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, description: e.target.value}})}/>
                            <TextField name='client' variant='outlined' label='Client - English' size={"small"} fullWidth
                                       value={projectData.eng.client}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, client: e.target.value}})}/>
                            <TextField name='architectures' variant='outlined' label='Architectures - English' size={"small"} fullWidth
                                       value={projectData.eng.architectures}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, architectures: e.target.value}})}/>
                            <TextField name='simulation' variant='outlined' label='Simulation - English' size={"small"} fullWidth
                                       value={projectData.eng.simulation}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, simulation: e.target.value}})}/>
                            <TextField name='partners' variant='outlined' label='Partners - English' size={"small"} fullWidth
                                       value={projectData.eng.partners}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, partners: e.target.value}})}/>
                            <TextField name='interior_designer' variant='outlined' label='Interior Designer - English' size={"small"} fullWidth
                                       value={projectData.eng.interior_designer}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, interior_designer: e.target.value}})}/>
                            <TextField name='interior_designer' variant='outlined' label='Photography - English' size={"small"} fullWidth
                                       value={projectData.eng.photography}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, photography: e.target.value}})}/>
                            <TextField name='project_manager' variant='outlined' label='Project Manager - English' size={"small"} fullWidth
                                       value={projectData.eng.project_manager}
                                       onChange={(e) => setProjectData({...projectData, eng: {...projectData.eng, project_manager: e.target.value}})}/>


                            <TextField name='title' variant='outlined' label='Title - Hebrew' size={"small"} fullWidth value={projectData.heb.title}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, title: e.target.value}})}/>
                            <TextField name='description' variant='outlined' label='Description - Hebrew' size={"small"} fullWidth
                                       value={projectData.heb.description}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, description: e.target.value}})}/>
                            <TextField name='client' variant='outlined' label='Client - Hebrew' size={"small"} fullWidth
                                       value={projectData.heb.client}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, client: e.target.value}})}/>
                            <TextField name='architectures' variant='outlined' label='Architectures - Hebrew' size={"small"} fullWidth
                                       value={projectData.heb.architectures}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, architectures: e.target.value}})}/>
                            <TextField name='simulation' variant='outlined' label='Simulation - Hebrew' size={"small"} fullWidth
                                       value={projectData.heb.simulation}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, simulation: e.target.value}})}/>
                            <TextField name='partners' variant='outlined' label='Partners - Hebrew' size={"small"} fullWidth
                                       value={projectData.heb.partners}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, partners: e.target.value}})}/>
                            <TextField name='interior_designer' variant='outlined' label='Interior Designer - Hebrew' size={"small"} fullWidth
                                       value={projectData.heb.interior_designer}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, interior_designer: e.target.value}})}/>
                            <TextField name='interior_designer' variant='outlined' label='Photography - Hebrew' size={"small"} fullWidth
                                       value={projectData.heb.photography}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, photography: e.target.value}})}/>

                            <TextField name='project_manager' variant='outlined' label='Project Manager - Hebrew' size={"small"} fullWidth
                                       value={projectData.heb.project_manager}
                                       onChange={(e) => setProjectData({...projectData, heb: {...projectData.heb, project_manager: e.target.value}})}/>

{/*
                            <div>
                                <FileBase
                                    type={"file"}
                                    multiple={false}
                                    onDone={({base64}) => {
                                        projectData.images.push(base64);
                                    }}
                                />
                            </div>*/}

                            <input id={"inputImages"} onChange={fileSelected} multiple type="file" accept="image/*"/>


                            <Button variant='contained' color="primary" size={"large"} type={"submit"}
                                    fullWidth>Submit</Button>
                            <Button variant='contained' color="secondary" size={"small"} onClick={clear}
                                    fullWidth>Clear</Button>
                        </form>
                    </Paper>
        </div>
    );
};

export default ProjectForm;