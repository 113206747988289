import * as api from '../api/images';
import { UPDATE, CREATE, DELETE, FETCH_ALL } from "../constants/actionTypes";


export const deleteImages = (id) => async (dispatch) => {
    try {
        await api.deleteImages(id);
        dispatch({type: DELETE, payload: id});
    } catch (err) {
        console.log(err);
    }
};

export const getImages = () => async (dispatch) => {
    try {
        const { data } = await api.fetchImages();
        dispatch({type: FETCH_ALL, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const createImages = (images) => async (dispatch) => {
    try {
        const { data } = await api.createImages(images);

        dispatch({type: CREATE, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const updateImages = (id, images) => async (dispatch) => {
    try {
        const {data} = await api.updateImages(id, images);
        dispatch({type: UPDATE, payload: data});
    } catch (err) {
        console.log(err);
    }
};

