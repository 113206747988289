import {API} from './MainApi';


const ARTICLES = '/articles';

export const fetchArticles = () => API.get(ARTICLES);
export const createArticle = (newArticle) => API.post(ARTICLES, newArticle);
export const updateArticle = (id, updatedArticle) => API.patch(`${ARTICLES}/${id}`, updatedArticle);
export const deleteArticle = (id) => API.delete(`${ARTICLES}/${id}`);


