import * as api from "../api/imagesBucket";
import {CREATE, DELETE} from "../constants/actionTypes";

export const postImageBucket = (image) => async (dispatch) => {
    const formData = new FormData();

    for(let i =0; i<image.length; i++) {
        [image].forEach(f => {
            formData.append("image", f[i])
        });
    }
    try {
        const result = await api.postImageBucket(formData);
        dispatch({type: CREATE, payload: result.data});
        return result.data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteImagesBucket = (id) => async (dispatch) => {
    try {
        await api.deleteImagesBucket(id);
        dispatch({type: DELETE, payload: id});
    } catch (err) {
        console.log(err);
    }
};