import React from "react";
import {useSelector} from "react-redux";
import {createSubject, deleteSubject, getSubjects, updateSubject} from "../../actions/subjects";
import {GenericComponent} from "../../constants/GenericComponent";
import GenericComponentManagements from "../GenericComponent/GenericComponentManagements";

const SubjectsManagements = () => {

    const allObjs = useSelector((state) => state.subjects);
    const functions = { get: getSubjects, update: updateSubject, delete: deleteSubject, create: createSubject};
    const empty = {
        eng: {subject: ''},
        heb: {subject: ''}
    };

    const subject = new GenericComponent('subject', empty, functions, allObjs);

    return (
        <>
            <GenericComponentManagements componentInfo={subject}/>
        </>
    );
};

export default SubjectsManagements;