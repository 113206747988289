import axios from "axios";


export const API = axios.create({ baseURL: 'https://yossi-rokach-server.herokuapp.com'});

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
return req;
});