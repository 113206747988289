import React, {useState, useEffect, useCallback} from 'react';
import { Link } from 'react-router-dom';
import {AppBar, Typography, Toolbar, Avatar} from "@material-ui/core";
import './Menu.css';
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {LOGOUT} from "../../constants/actionTypes";
import {useHistory, useLocation} from 'react-router-dom';
import decode from 'jwt-decode';

const Menu = () => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const dispatch = useDispatch();
    const location = useLocation();

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const history = useHistory();

    const logout = useCallback(() => {
          dispatch({ type: LOGOUT});
          history.push('/');
          setUser(null);
    },[dispatch, history]);

    useEffect(() => {
        const token = user?.token;

        if(token) {
            const decodedToken = decode(token);
            if(decodedToken.exp * 1000 < new Date().getTime()) {
                logout();
            }
        }
        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location, user?.token, logout] );


    return (
        <>
            <AppBar position="static" color="inherit">
                <Typography variant="h2" align="center">Site Management</Typography>
            </AppBar>

            <nav className='navbar'>
                <div className='navbar-container'>

                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>

                    <Toolbar style={{ justifyContent: "center",display: "flex"}}>
                        {user?.result ? (
                            <div >
                                <Avatar style={{display: "inLine-flex"}}  alt={user.result.name} src={user.result.imageUrl} >{user.result.name.charAt(0)} conneted</Avatar>
                                <Button variant="contained" color={"secondary"} onClick={logout}>Logout</Button>
                            </div>
                        ) : (
                            <Button component={Link} to={'/auth'} variant="contained" color={"primary"}>Sign-In</Button>
                        )}
                    </Toolbar>

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links'>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/images'
                                className='nav-links'
                            >
                                Images
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/projects'
                                className='nav-links'
                            >
                                Projects
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/articles'
                                className='nav-links'
                            >
                                Article
                            </Link>
                        </li>

                    </ul>


                </div>
            </nav>
        </>
    );
};

export default Menu;