import React from 'react';
import {useSelector} from "react-redux";
import {getArticles, createArticle, deleteArticle, updateArticle} from "../../actions/articles";
import {GenericComponent} from "../../constants/GenericComponent";
import GenericComponentManagements from "../GenericComponent/GenericComponentManagements";


const ArticlesManagements = () => {

    const allObjs = useSelector((state) => state.articles);
    const functions = { get: getArticles, update: updateArticle, delete: deleteArticle, create: createArticle};
    const empty = {
        eng: {
            title: '',
            sub_title: '',
            description: '',
        },
        heb: {
            title: '',
            sub_title: '',
            description: '',
        },
        link: '',
        images: []
    };

    const article = new GenericComponent('articles', empty, functions, allObjs);

    return (
        <>
            <GenericComponentManagements componentInfo={article}/>
        </>
    );
};

export default ArticlesManagements;