import * as api from '../api/article';
import { UPDATE, CREATE, DELETE, FETCH_ALL} from "../constants/actionTypes";

export const getArticles = () => async (dispatch) => {
    try {
        const { data } = await api.fetchArticles();
        dispatch({type: FETCH_ALL, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const createArticle = (articles) => async (dispatch) => {
    try {
        const { data } = await api.createArticle(articles);

        dispatch({type: CREATE, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const updateArticle = (id, articles) => async (dispatch) => {
    try {
        const {data} = await api.updateArticle(id, articles);
        dispatch({type: UPDATE, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const deleteArticle = (id) => async (dispatch) => {
    try {
        await api.deleteArticle(id);
        dispatch({type: DELETE, payload: id});
    } catch (err) {
        console.log(err);
    }
};
