import React from 'react';


const Home = () => {

    return (
        <div style={{textAlign:'center'}}>
            <h1>Home</h1>
            <h3>This site is for updating data only! </h3>
        </div>
    )
};

export default Home;