import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Button, Paper, TextField, Typography} from "@material-ui/core";
import {postImageBucket} from "../../../actions/imagesBucket";

const GenericForm = ({currentObjectId, setCurrentObjId, dynamicObj, componentInfo }) => {

    const dispatch = useDispatch();
    const [file,setFile] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const fileSelected = (event) => {
        const tempArr = [];
        [...event.target.files].forEach(f => {
            tempArr.push(f);
        });
        setFile(tempArr);
    };

    const [objectData, setObjectData] = useState({...componentInfo.empty});

    useEffect(() => {
        if(dynamicObj) setObjectData(dynamicObj);
    }, [dynamicObj]);

    const user = JSON.parse(localStorage.getItem('profile'));

    async function handleSubmit(e) {
        e.preventDefault();

        const fd = await dispatch(postImageBucket(file));
        const files = await (fd.toString().split(","));

        if(objectData.images.length === 0) {
            objectData.images = files;
        } else {
            const arr = objectData.images.concat(files);
            objectData.images = arr;
        }

        if(currentObjectId) {
            dispatch(componentInfo.functions.update(currentObjectId, objectData));
        } else {
            dispatch(componentInfo.functions.create(objectData));
        }
        clear();
    }

    const clear = () => {
        document.getElementById('inputImages').value = '';
        setCurrentObjId(null);
        setObjectData({...componentInfo.empty});
    };

    if(!user?.result?.name) {
        return (
            <Paper>
                <Typography variant='h6' align='center'>
                    please sign in to add
                </Typography>
            </Paper>
        )
    }

    return (
        <div className="sticky">
            <Paper className="container">
                {isLoading ? null :
                    <form autoComplete="off" noValidate onSubmit={(e) => handleSubmit(e)}>

                        {objectData.eng ? Object.entries(objectData.eng).map(([key, value], index) => {
                                return (
                                    <TextField key={index} name={`${key} - English`} variant='outlined'
                                               label={`${key} - English`} size={"small"} fullWidth
                                               value={(typeof value) === 'string' ? value : ''}
                                               onChange={(e) => {
                                                   setObjectData({
                                                       ...objectData,
                                                       eng: {...objectData.eng, [key]: e.target.value}
                                                   });
                                               }}/>
                                );
                            }
                        ) : null}

                        {objectData.heb ? Object.entries(objectData.heb).map(([key, value], index) => {
                                return (
                                    <TextField key={index} name={`${key} - Hebrew`} variant='outlined'
                                               label={`${key} - Hebrew`} size={"small"} fullWidth
                                               value={(typeof value) === 'string' ? value : ''}
                                               onChange={(e) => {
                                                   setObjectData({
                                                       ...objectData,
                                                       heb: {...objectData.heb, [key]: e.target.value}
                                                   });
                                               }}/>
                                );
                            }
                        ) : null}

                        {(componentInfo.empty.link) != null ?
                            <TextField name={`Link`} variant='outlined' label={`Link`} size={"small"} fullWidth
                                       value={objectData.link}
                                       onChange={(e) =>
                                           setObjectData({...objectData, link: e.target.value})}
                            /> : <div></div>}

                        {(componentInfo.empty.title) != null ?
                            <TextField name={`title - English`} variant='outlined' label={`title - English`}
                                       size={"small"}
                                       value={(typeof objectData.title) === 'string' ? objectData.title : ''}
                                       onChange={(e) => {
                                           setObjectData({...objectData, title: e.target.value})
                                       }}
                            /> : <div></div>}

                        <input id={"inputImages"} onChange={fileSelected} multiple type="file" accept="image/*"/>

                        {/*                    {objectData.images ?
                        <div >
                            <FileBase
                                type={"file"}
                                multiple={false}
                                onDone={({base64}) => {
                                    objectData.images.push(base64);
                                }}
                            />
                        </div>
                    : <div></div>}*/}


                        <Button variant='contained' color="primary" size={"large"} type={"submit"}
                                fullWidth>Submit</Button>
                        <Button variant='contained' color="secondary" size={"small"} onClick={clear}
                                fullWidth>Clear</Button>

                    </form>
                }
            </Paper>
        </div>
    );
};

export default GenericForm;