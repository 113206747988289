import * as api from '../api/project';
import { UPDATE, CREATE, DELETE, FETCH_ALL} from "../constants/actionTypes";

export const getProjects = () => async (dispatch) => {
    try {
        const { data } = await api.fetchProjects();
        dispatch({type: FETCH_ALL, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const createProject = (project) => async (dispatch) => {
    try {
        const { data } = await api.createProject(project);
        dispatch({type: CREATE, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const updateProject = (id, project) => async (dispatch) => {
    try {
        const {data} = await api.updateProject(id, project);
        dispatch({type: UPDATE, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const deleteProject = (id) => async (dispatch) => {
    try {
        await api.deleteProject(id);
        dispatch({type: DELETE, payload: id});
    } catch (err) {
        console.log(err);
    }
};