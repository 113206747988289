import React from 'react';
import {CircularProgress, Grid} from "@material-ui/core";
import GenericComponent from "./GenericComponent/GenericComponent";


const GenericComponents = ({ setCurrentObjId, componentInfo}) => { // state like - const images = useSelector((state) => state.images);

    return (
        <>
            {!componentInfo.allObjs.length ? <CircularProgress /> : (
                <Grid component="span" container alignItems="stretch" spacing={3}>
                    {
                        componentInfo.allObjs.map((dynamicObj) => (
                            <Grid container item key={dynamicObj._id} style={{paddingTop: '10px'}} >
                                <GenericComponent dynamicObj={dynamicObj} setCurrentObjId={setCurrentObjId} componentInfo={componentInfo}/>
                            </Grid>
                        ))
                    }
                </Grid>
            )}
        </>
    );
};

export default GenericComponents;