export const Residential = {label:'Residential', name: 'Residential'};
export const Offices_Commerce = {label:'Offices And Commerce', name: 'Offices_Commerce'};
export const Campuses_Public_Institutions = {label:'Campuses And Public Institutions', name: 'Campuses_Public_Institutions'};
export const Gardens_Parks = {label:'Gardens and parks', name: 'Gardens_Parks'};
export const Urban_Design = {label:'Urban design', name: 'Urban_Design'};
export const Competitions = {label:'Competitions', name: 'Competitions'};
export const MasterPlans = {label:'Master plans', name: 'MasterPlans'};

export const ALL = {label:'ALL', name: 'ALL'};

export const SUBJECTS_TYPES = [ALL, Residential, Offices_Commerce, Campuses_Public_Institutions, Gardens_Parks, Urban_Design, Competitions, MasterPlans];

