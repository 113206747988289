import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AppBar, Container, Grid, Grow, Typography} from "@material-ui/core";
import GenericForm from './GenericForm/GenericForm';
import GenericComponents from './GenericComponents/GenericComponents';
import {getArticles} from "../../actions/articles";
import {getImages} from "../../actions/images";


const GenericComponentManagements = ({ componentInfo }) => {

    const [currentObjectId, setCurrentObjId] = useState(null);
    const dispatch = useDispatch();
    const dynamicObj = currentObjectId ? componentInfo.allObjs.find((obj) => obj._id === currentObjectId) : null;

    useEffect(() => {
        if(currentObjectId == null) {
            componentInfo.title === 'articles' ? dispatch(getArticles()) : dispatch(getImages());
            // dispatch(componentInfo.functions.get());
        }
    }, [ currentObjectId, dispatch, componentInfo.title ] );


    return (
        <>
            <Container component="span">
                <AppBar position="static" color="inherit">
                    <Typography variant="h4" align="center">{componentInfo.title}</Typography>
                </AppBar>
                <Grow in>
                    <Container style={{paddingTop: '30px'}}>
                        <Grid container style={{justifyContent: 'space-between'}} spacing={3}>
                            <Grid item xs={12} sm={8}>
                                <GenericComponents setCurrentObjId={setCurrentObjId} currentObjectId={currentObjectId} componentInfo={componentInfo}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <GenericForm key={componentInfo.title} currentObjectId={currentObjectId} setCurrentObjId={setCurrentObjId}
                                             dynamicObj={dynamicObj} componentInfo={componentInfo}
                                             />
                            </Grid>
                        </Grid>
                    </Container>
                </Grow>
            </Container>
        </>
    );
};

export default GenericComponentManagements;