import { FETCH_ALL, DELETE, UPDATE, CREATE} from "../constants/actionTypes";

const images =  (images = [] , action) => {
    switch (action.type) {
        case FETCH_ALL:
            return action.payload;

        case CREATE:
            return [...images, action.payload];

        case UPDATE:
            return images.map((image) => image._id === action.payload._id ? action.payload : image);

        case DELETE:
            return images.filter((image) => image._id !== action.payload);

        default:
            return images;
    }
}

export default images;