import React from 'react';
import {useSelector} from "react-redux";
import {createImages, deleteImages, getImages, updateImages} from "../../actions/images";
import GenericComponentManagements from "../GenericComponent/GenericComponentManagements";
import {GenericComponent} from "../../constants/GenericComponent";

const ImagesManagements = () => {

    const allObjs = useSelector((state) => state.images);
    const functions = { get: getImages, update: updateImages, delete: deleteImages, create: createImages};
    const empty = {
        title: '',
        images: []
    };

    const images = new GenericComponent('images', empty, functions, allObjs);

    return (
        <>
            <GenericComponentManagements componentInfo={images}/>
        </>
    );
};

export default ImagesManagements;