import {useDispatch} from "react-redux";
import React, { useState, useEffect } from "react";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import {deleteImagesBucket} from "../../../../actions/imagesBucket";

const GenericComponent = ({ dynamicObj, setCurrentObjId, componentInfo }) => {

    const dispatch = useDispatch();
    const [objData, setObjData] = useState(dynamicObj);

    const user = JSON.parse(localStorage.getItem('profile'));

    useEffect(() => {
        if (objData) setObjData(objData);
    }, [objData]);

    async function handleRemoveImage(e, imageIndex, imageId) {
        e.preventDefault();
        (dynamicObj.images).splice(imageIndex, 1);
        setObjData({...objData, images: dynamicObj.images});
        dispatch(componentInfo.functions.update(dynamicObj._id, dynamicObj));
        await dispatch(deleteImagesBucket(imageId));
    }

    //console.log(dynamicObj);

    return (
        <>
            <Paper className={"paper"}>
                <div>
                    <Button style={{color:'black'}} size="small" onClick={() => setCurrentObjId(dynamicObj._id)} >
                        {user && <MoreHorizIcon />}
                    </Button>

                    <Button style={{color:'black'}} size="small" onClick={() => dispatch(componentInfo.functions.delete(dynamicObj._id))} >
                        {user && <DeleteIcon />}
                    </Button>
                </div>

                <div className="info">

                    <Grid item className="english" >
                        <Typography component="span" variant={"h5"} className={"title"} color={"primary"} size={"large"}>English</Typography>

                        {dynamicObj.eng ? Object.entries(dynamicObj.eng).map(([key, value],index) => {
                            return (<Typography key={index} component="span" className={"title"} color={"primary"}>{key.toUpperCase()}: <Typography component="p" className={"result"} color={"secondary"}>{value}</Typography> </Typography>);
                            }
                        ) : null }
                    </Grid>

                    <Grid item className="hebrew" >
                        <Typography component="span" variant={"h5"} className={"title"} color={"primary"} size={"large"}>עברית</Typography>

                        {dynamicObj.heb ? Object.entries(dynamicObj.heb).map(([key, value],index) => {
                                return (<Typography key={index} component="span" className={"title"} color={"primary"}>{key.toUpperCase()}: <Typography component="p" className={"result"} color={"secondary"}>{value}</Typography> </Typography>);
                            }
                        ) : null}
                    </Grid>


                    {dynamicObj.title || dynamicObj.title===''  ?
                        <Typography key={dynamicObj.title} component="span" className={"title"} color={"primary"}>{'TITLE'}: <Typography component="p" className={"result"} color={"secondary"}>{dynamicObj.title}</Typography> </Typography>
                        : null}

                    {dynamicObj.link || dynamicObj.link === ''?
                        <Typography key={dynamicObj.link} component="span" className={"title"} color={"primary"}>{'LINK'}: <Typography component="p" className={"result"} color={"secondary"}>{dynamicObj.link}</Typography> </Typography>
                        : null}

                    { dynamicObj.images ?
                    <div>
                        <Typography  component="span" className={"title"} color={"primary"}>Number Of Images: <Typography component="p" className={"result"} color={"secondary"}>{dynamicObj.images.length}</Typography> </Typography>
                        {(dynamicObj.images).map((img, index) => (
                            <div key={index} className={"images"}>
                                <Button  style={{color:'black'}} size="small" className={"deleteButton"}
                                         onClick={(e) => {handleRemoveImage(e,index,img);} }
                                >
                                    {user && <DeleteIcon />}
                                </Button>
                                <img  src={"https://yossi-rokach-server.herokuapp.com/imagesBucket/" + img} alt={img} key={img}/>
                            </div>
                        ))}
                    </div>
                        : <div></div> }
                </div>
            </Paper>
        </>
    );
};

export default GenericComponent;