import React from 'react';
import ProjectsManagements from "./components/ProjectsManagements/ProjectsManagements";
import Menu from "./components/Menu/Menu";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ImagesManagements from "./components/ImagesManagements/ImagesManagements";
import ArticlesManagements from "./components/ArticlesManagements/ArticlesManagements";
import Home from "./components/Home/Home";
import NotFound from "./components/NotFound/NotFound";
import SubjectsManagements from "./components/Subjects/SubjectsManagements";
import Auth from "./components/Auth/Auth";
import {LOCAL_STORAGE} from "./constants/Constants";


const App = () => {

    const user = JSON.parse(localStorage.getItem("profile"));

    return (
        <Router>
            <Menu/>
            <Switch>
                <Route path='/' exact component={Home} lazy={true} />
                <Route path='/images' component={ImagesManagements}  />
                <Route path='/projects' component={ProjectsManagements} />
                <Route path='/subjects' component={SubjectsManagements} />
                <Route path='/articles' component={ArticlesManagements} />

                <Route path='/auth' component={() => (!user ? <Auth/> : <Redirect to={"/"}/>)}/>
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default App;