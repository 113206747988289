import axios from 'axios';

// const url = 'https://yossirokach-data-server.herokuapp.com/subjects';
const url = 'http://localhost:5000/subjects';

export const fetchSubjects = () => axios.get(url);

export const createSubject = (newSubject) => axios.post(url, newSubject);

export const updateSubject = (id, updatedSubject) => axios.patch(`${url}/${id}`, updatedSubject);

export const deleteSubject = (id) => axios.delete(`${url}/${id}`);
