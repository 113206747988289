import axios from "axios";
import {API} from './MainApi';


const PROJECTS = '/projects';

export const fetchProjects = () => API.get(PROJECTS);

export const createProject = (newProject) => API.post(PROJECTS, newProject);
export const updateProject = (id, updatedProject) => API.patch(`${PROJECTS}/${id}`, updatedProject);
export const deleteProject = (id) => API.delete(`${PROJECTS}/${id}`);
