import { FETCH_ALL, DELETE, CREATE} from "../constants/actionTypes";

const subjects = (subjects = [] , action) => {
    switch (action.type) {
        case FETCH_ALL:
            return action.payload;

        case CREATE:
            return [...subjects, action.payload];

        case DELETE:
            return subjects.filter((subject) => subject._id !== action.payload);

        default:
            return subjects;
    }
}

export default subjects;