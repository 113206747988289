import React, {useEffect, useState} from 'react';
import {AppBar, Container, Grid, Grow, Typography} from "@material-ui/core";
import Projects from "./Projects/Projects";
import ProjectForm from "./ProjectForm/ProjectForm";
import {useDispatch} from "react-redux";
import {getProjects} from "../../actions/projects";


const ProjectsManagements = () => {

    const [currentProjectId, setCurrentProjectId] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProjects());
    }, [currentProjectId, dispatch]);

    return (
        <>
            <Container component="span">
                <AppBar position="static" color="inherit">
                    <Typography variant="h4" align="center">Projects</Typography>
                </AppBar>
                <Grow in>
                    <Container style={{paddingTop: '30px'}}>
                        <Grid container style={{justifyContent: 'space-between'}} spacing={3}>
                            <Grid item xs={12} sm={8}>
                                <Projects setCurrentProjectId={setCurrentProjectId}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ProjectForm key={"project"} currentProjectId={currentProjectId} setCurrentProjectId={setCurrentProjectId}/>
                            </Grid>
                        </Grid>
                    </Container>
                </Grow>
            </Container>
        </>
    );
};


export default ProjectsManagements;