import {API} from './MainApi';


const IMAGES = '/images';

export const fetchImages = () => API.get(IMAGES);
export const updateImages = (id, updatedImages) => API.patch(`${IMAGES}/${id}`, updatedImages);
export const createImages = (newImages) => API.post(IMAGES, newImages);
export const deleteImages = (id) => API.delete(`${IMAGES}/${id}`);

