import * as api from '../api/subject';
import {CREATE, DELETE, FETCH_ALL, UPDATE} from "../constants/actionTypes";

export const getSubjects = () => async (dispatch) => {
    try {
        const { data } = await api.fetchSubjects();
        dispatch({type: FETCH_ALL, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const createSubject = (subject) => async (dispatch) => {
    try {
        const { data } = await api.createSubject(subject);

        dispatch({type: CREATE, payload: data});
    } catch (err) {
        console.log(err);
    }
};

export const updateSubject = (id, subject) => async (dispatch) => {
    try {
        const {data} = await api.updateSubject(id, subject);
        dispatch({type: UPDATE, payload: data});
    } catch (err) {
        console.log(err);
    }
}

export const deleteSubject = (id) => async (dispatch) => {
    try {
        await api.deleteSubject(id);
        dispatch({type: DELETE, payload: id});
    } catch (err) {
        console.log(err);
    }
};
