import * as api from '../api/users';
//import {AUTH, LOGOUT} from "../constants/actionTypes";
import {AUTH} from "../constants/actionTypes";


export const googlelogin = (tokenId, history) => async (dispatch) => {
    try {
        const data = await api.googlelogin({tokenId});
        if(data.status !== 200) return data.message;
        dispatch({ type: AUTH, data : data.data});
        history.push('/');
        return null;
    } catch (err) {
        console.log(err);
    }
};


/*export const signin = (formData, history) => async (dispatch) => {
    try {
        const { data } = await api.signIn(formData);

        dispatch({ type: AUTH, data});
        history.push('/');
    } catch (err) {
        console.log(err);
    }
};

export const signup = (formData, history) => async (dispatch) => {
    try {
        const { data } = await api.signUn(formData);

        dispatch({ type: LOGOUT, data});
        history.push('/');
    } catch (err) {
        console.log(err);
    }
};*/
